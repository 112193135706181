import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className="CompName">
          Rawana Analytics
        </p>
        <p className="TagLine">
        Bespoke Analytics
        <br/>
        info@rawana.awsapps.com
        </p>

      </header>
    </div>
  );
}

export default App;
